@import '../../styles/customMediaQueries.css';

.root {}

.pageRoot {
  /* padding-bottom: 90px; */

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 20px 0;
  width: 100%;
  padding: 15px 24px;

  @media (--viewportLarge) {
    margin: 0 0 20px 0;
  }
}

.actionBarForHeroLayout {
  position: relative;
  width: 100%;

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.bannerImage {
  border-radius: 20px;
  overflow: hidden;
  border: solid 1px var(--colorGrey100);
  background-color: var(--colorWhite);
  height: 100%;
  padding-bottom: 66.66%;
  position: relative;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }

  &>img {
    max-width: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &>div {
    position: absolute;
    height: 100%;

    @media (--viewportMedium) {
      position: relative;
    }
  }
}

.ownListingText {
  composes: h5 from global;
  padding: 0;
  margin: 0;
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.editIcon {
  margin: -4px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 150px;

  @media (--viewportLarge) {
    padding-bottom: 0;
    padding-left: 0px;
    padding-right: 0px;
  }

  & .productGallery {
    position: relative;
    margin: 0 0 36px 0;

    @media (--viewportMedium) {
      margin: 24px 0 48px 0;
    }

    @media (--viewportLarge) {
      margin: 0 0 40px 0;
      padding-bottom: 4px;
    }

    & :global(.image-gallery-thumbnails-wrapper) {
      display: none;
    }

    & :global(.image-gallery-slide > div > div) {
      padding-bottom: 46% !important;
    }
  }
}

.mainContentWrapper {
  display: flex;
  align-items: flex-start;
  border-bottom: solid 1px var(--colorGrey100);
  padding-bottom: 32px;
  margin-top: 24px;
  margin-bottom: 32px;
  flex-direction: column;

  @media (--viewportMedium) {
    margin-top: 32px;
  }

  @media (--viewportLarge) {
    flex-direction: row;
  }

  & .mainContentLeft {
    width: 100%;

    @media (--viewportLarge) {
      width: calc(100% - 400px);
      padding-right: 40px;
    }

    & .listingPageHeader {
      border-bottom: solid 1px var(--colorGrey100);
      align-items: flex-start;
      padding-bottom: 32px;
      margin-bottom: 32px;

      & .listingPageHeaderLeft {
        & .orderPanelTitle {
          margin-bottom: 8px;
        }
      }
    }

    & .userProfile {
      width: 56px;
      height: 56px;
      border-radius: 100px;
      overflow: hidden;
      position: relative;

      &>img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    & .experienceSec {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 32px;
      align-items: flex-start;

      @media (--viewportSmall) {
        grid-template-columns: repeat(3, 1fr);
      }

      & .experienceBlock {
        display: flex;
        flex-direction: column;

        &:last-child {
          margin-bottom: 0;
        }

        & .experienceIcon {
          &>svg {
            width: 20px;
            height: 20px;

            & path {
              fill: var(--colorBlack);
              opacity: 1;
            }
          }
        }

        & .experienceInfo {
          margin-top: 15px;

          &>h2 {
            font-size: 18px;
            color: var(--colorBlack);
            margin: 0 0 8px 0;
            line-height: 100%;
          }

          &>p {
            font-size: 16px;
            color: var(--colorGrey500);
            margin: 0;
            line-height: 100%;
          }
        }
      }
    }

    & .personalDetails {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 14px;

      @media (--viewportSmall) {
        grid-template-columns: repeat(3, 1fr);
      }

      & .infoBlock {
        width: 100%;
        background-color: var(--colorWhite);
        padding: 20px;
        border: solid 1px var(--colorGrey100);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 24px;
        font-weight: var(--fontWeightRegular);
        color: var(--colorBlack);

        & .label {
          font-size: 16px;
          font-weight: var(--fontWeightBold);
          line-height: 130%;
          margin: 0 0 8px 0;
          color: var(--colorGrey800);
          padding: 0;
        }
      }


    }
  }

  & .mainContentRight {
    width: 100%;
    align-self: flex-start;
    display: flex;

    @media (--viewportLarge) {
      border: solid 1px rgba(17, 17, 17, 0.04);
      position: sticky;
      max-width: 400px;
      top: 130px;
      padding: 20px;
      border-radius: 10px;
      background-color: rgb(245, 245, 244);
    }

    & .productOrderPanel {
      width: 100%;

      @media (--viewportLarge) {
        display: block;
      }
    }
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 0 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: unset;
    width: min(calc(var(--contentMaxWidth) + 72px), 100vw);
    padding: 36px 36px 0 36px;
    margin: 0 auto 117px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  font-weight: var(--fontWeightBold);
  width: 100%;
  margin: 0;
  color: var(--colorBlack);
  font-size: 24px;
  line-height: 120%;
  padding: 0;
  margin-bottom: 20px;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }

  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  /* display: flex; */
}

.inquiryModaldetails {

  &>div {
    margin-top: 60px;
    text-align: center;
    font-size: 22px;
    color: #000000;
  }

  & a {
    display: block;
    width: 100%;
    min-height: 56px;
    margin: 0;
    padding: 16px 0 16px 0;
    transition: all var(--transitionStyleButton);
    cursor: pointer;
    font-weight: var(--fontWeightSemiBold);
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: var(--borderRadiusLarge);
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    margin-top: 50px;
  }
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.moodboardSlider {
  width: 100%;
  margin-bottom: 48px;
  overflow-x: hidden;

  & :global(.slick-track) {
    display: flex;
    align-items: center;
  }

  & :global(.slick-slider) {
    width: 100%;
    position: relative;

    & .moodboardImage {
      width: 100%;
      height: 100%;
    }
  }

  & :global(.slick-list) {
    overflow: hidden;
    position: relative;
    margin: 0 -10px;
  }

  & :global(.slick-slide) {
    padding: 0 10px;
  }

  & :global(.slick-arrow) {
    position: absolute;
    top: -30px;
    right: 0;
  }

  & :global(.slick-next) {
    font-size: 0;
    line-height: 0;
    width: 54px;
    height: 54px;
    right: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    z-index: 1;
    background-repeat: no-repeat !important;
    background-image: url("../../assets/images/next.png") !important;
    background-size: cover;

    @media (max-width: 1023px) {
      cursor: default;
    }

    &:before {
      content: unset;
    }

    /* &:hover {
      background: url("data:image/svg+xml,%3Csvg width='51' height='9' viewBox='0 0 51 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M50.8536 5.02714C51.0488 4.83188 51.0488 4.51529 50.8536 4.32003L47.6716 1.13805C47.4763 0.942788 47.1597 0.942788 46.9645 1.13805C46.7692 1.33331 46.7692 1.64989 46.9645 1.84516L49.7929 4.67358L46.9645 7.50201C46.7692 7.69727 46.7692 8.01386 46.9645 8.20912C47.1597 8.40438 47.4763 8.40438 47.6716 8.20912L50.8536 5.02714ZM0.5 5.17358H50.5V4.17358H0.5V5.17358Z' fill='black'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      color: unset;
    } */
  }

  & :global(.slick-prev) {
    font-size: 0;
    line-height: 0;
    left: unset;
    width: 50px;
    height: 50px;
    right: 80px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    top: -32px;
    border: none;
    outline: 0;
    z-index: 1;
    background-repeat: no-repeat !important;
    background-image: url("../../assets/images/back.png") !important;
    background-size: cover;

    @media (max-width: 1023px) {
      cursor: default;
    }

    &:before {
      content: unset;
    }

    /* &:hover {
      background: url("data:image/svg+xml,%3Csvg width='51' height='9' viewBox='0 0 51 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.146447 4.32003C-0.0488155 4.51529 -0.0488155 4.83188 0.146447 5.02714L3.32843 8.20912C3.52369 8.40438 3.84027 8.40438 4.03553 8.20912C4.2308 8.01386 4.2308 7.69727 4.03553 7.50201L1.20711 4.67358L4.03553 1.84516C4.2308 1.64989 4.2308 1.33331 4.03553 1.13805C3.84027 0.942788 3.52369 0.942788 3.32843 1.13805L0.146447 4.32003ZM0.5 5.17358H50.5V4.17358H0.5V5.17358Z' fill='black'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      color: unset;
    } */
  }
}

.sliderContents {
  text-align: center;
}

.sliderImage {
  padding: 0;
  width: fit-content !important;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & .imageExpand {
    position: absolute;
    width: 28px;
    height: 28px;
    right: 26px;
    bottom: 6px;
    top: unset;
    left: unset;
    cursor: pointer;
    object-position: center;
    background: #f2f2f242;
    border-radius: 100px;
    object-fit: contain;

    @media (max-width: 767px) {
      right: 13px;
      bottom: 3px;
      width: 19px;
      height: 19px;
    }
  }
}

.sectionHeading {
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 130%;
  margin: 0 0 8px 0;
  color: var(--colorBlack);
}

.modelProfileDetails {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  & .modelProfileImg {
    width: 100%;

    @media (--viewportMedium) {
      width: 30%;
    }
  }

  & .modelProfileInfo {
    width: 100%;
    margin-top: 24px;

    & .verifiedIcon {

      & svg {
        fill: rgb(0, 166, 255);
        padding-bottom: 3px;
      }

    }
    @media (--viewportMedium) {
      margin-top: 0;
      width: 40%;
      padding-left: 32px;
    }

    @media (--viewportLarge) {
      padding-left: 48px;
    }

    & .personalDetails {
      display: flex;
      flex-direction: column;

      & .profileName {
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 18px;
        color: #000000;
      }

      & .infoBlock {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 14px;
        font-weight: var(--fontWeightRegular);
        color: var(--colorBlack);
        margin: 0 0 10px 0;



        & .label {
          font-size: 14px;
          font-weight: var(--fontWeightSemiBold);
          line-height: 130%;
          margin: 0;
          color: var(--colorGrey800);
          padding: 0 8px 0 0;
          white-space: nowrap;
        }

        & .sendBtn {
          width: auto;
          height: auto;
          min-height: auto;
          padding: 10px 20px;
          min-width: 200px;
          margin-top: 14px;
        }
      }
    }
  }
}

.imageGrid {
  display: block;
  column-count: 3;
  gap: 30px;

  @media (max-width:768px) {
    column-count: 2;
  }

  @media (max-width:460px) {
    column-count: 1;
  }
}

.portfolioImages {
  /* & img {
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    aspect-ratio: 4/5;
    border-radius: 10px;
  } */

  & .portfolioGrid {
    display: block;
    column-count: 3;
    gap: 12px;

    @media (max-width:768px) {
      column-count: 2;
    }

    @media (max-width:460px) {
      column-count: 1;
    }

    &>div {
      /* height: 100%;
      width: 100%; */
      break-inside: avoid;
      margin-bottom: 12px;

      @media (min-width:768px) {


        /* &:nth-child(1) {
          grid-column: 1 / span 1;
          grid-row: 1 / span 1;
        }

        &:nth-child(2) {
          grid-column: 2 / span 1;
          grid-row: 1 / span 1;
        }

        &:nth-child(3) {
          grid-column: 3 / span 1;
          grid-row: 1 / span 2;
        }

        &:nth-child(4) {
          grid-column: 1 / span 2;
          grid-row: 2 / span 1;
        }

        &:nth-child(5) {
          grid-column: 3 / span 1;
          grid-row: 3 / span 1;
        }

        &:nth-child(6) {
          grid-column: 1 / span 2;
          grid-row: 3 / span 2;
        }

        &:nth-child(7) {
          grid-column: 3 / span 1;
          grid-row: 1 / span 2;
        } */
      }

      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
      }
    }
  }
}

.modelHireType {
  & .topBarHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width:1024px) {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    @media (max-width:560px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    & .headingName {
      font-weight: var(--fontWeightBold);
      width: 100%;
      color: var(--colorBlack);
      font-size: 28px;
      line-height: 120%;
      margin-bottom: 0;
    }

    & .applyButton {
      & button {
        width: 150px;

        @media (max-width:768px) {
          min-height: 40px;
        }
      }
    }
  }

  & .hireData {
    & .listLine {
      & .locationLine {
        margin-bottom: 5px;

        & .iconList {}

        & .iconName {
          font-size: 14px;
          margin-left: 5px;
        }
      }
    }
  }
}

.requirementPayment {
  width: 55%;
  display: flex;
  gap: 20px;
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
  justify-content: space-between;
  border-top: 1px solid #ebebeb;
  border: 1px solid rgb(0 0 0 / 15%);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.10);

  @media (max-width:575px) {
    width: 100%;
  }

  & .requirementBox {
    & .requirementHeading {
      width: 100%;
      font-size: 14px;
      line-height: 120%;
      margin-bottom: 10px;
      color: var(--colorBlack);
      font-weight: var(--fontWeightBold);

    }

    & .paymentCard {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;
      color: hsl(0deg 0% 41.26%);
    }
  }
}

.jobDescription {
  margin-top: 20px;
  margin-bottom: 40px;

  & .descriptionTitle {
    line-height: 110%;
    font-weight: var(--fontWeightBold);
    color: var(--colorBlack);
    font-size: 18px;
    margin-bottom: 15px;
  }

  & .descriptionBox {

    & pre {
      border: 1px solid #dbdbdb;
      border-radius: 4px;
      font-size: 14px;
      padding: 10px;
      white-space: pre-wrap;
    }
  }

  & .profileDescription  {

  }
}


.gridImage {
  cursor: pointer;
  margin-bottom: 30px;

  & img {
    border-radius: 20px;
  }
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 0 15px;

  @media(max-width: 768px) {
    padding: 0 30px;
  }
}

.closeButton {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  & svg {
    & path {
      &:nth-child(2) {
        fill: #b4b4b4;
      }
    }

    &:hover {
      & path {
        &:nth-child(2) {
          fill: #fff;
        }
      }
    }
  }
}

.lightboxImage {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  border: 2px solid white;
}

.galleryImage {
  object-fit: contain !important;
  max-width: 100%;
  max-height: 100%;
}

.navButtonLeft {
  cursor: pointer;
  background: #858585c4;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 200px;
  border-radius: 100%;

  @media(max-width:1024px) {
    left: 50px;
  }

  & svg {
    width: 35px;
    height: 35px;

    & path {
      &:nth-child(2) {
        fill: #000000;
      }
    }

    &:hover {
      & path {
        &:nth-child(2) {
          fill: #fff;
        }
      }
    }
  }
}

.navButtonRight {
  cursor: pointer;
  background: #858585c4;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: 200px;

  @media(max-width:1024px) {
    right: 50px;
  }


  & svg {
    width: 35px;
    height: 35px;

    & path {
      &:nth-child(2) {
        fill: #000000;
      }
    }

    &:hover {
      & path {
        &:nth-child(2) {
          fill: #fff;
        }
      }
    }
  }
}
